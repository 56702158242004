import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/actions/regrade/[sessionId]": [~14,[3]],
		"/actions/sessionNotFound": [15,[3]],
		"/actions/updateGrade/[updateGradeId]": [16,[3]],
		"/actions/viewInbox/[campaignId]": [~17],
		"/auth": [18],
		"/auth/oauth/checkpoint": [19],
		"/auth/recover": [20],
		"/auth/register": [21],
		"/secure/client-auth-success": [22],
		"/signout": [23],
		"/[organizationId]": [~5],
		"/[organizationId]/campaigns": [6,[2]],
		"/[organizationId]/campaigns/[campaignUID]": [7,[2]],
		"/[organizationId]/campaigns/[campaignUID]/sources": [~8,[2]],
		"/[organizationId]/campaigns/[campaignUID]/sources/new": [13,[2]],
		"/[organizationId]/campaigns/[campaignUID]/sources/[sourceID]": [9,[2]],
		"/[organizationId]/campaigns/[campaignUID]/sources/[sourceID]/integrations": [~10,[2]],
		"/[organizationId]/campaigns/[campaignUID]/sources/[sourceID]/integrations/[integrationID]": [11,[2]],
		"/[organizationId]/campaigns/[campaignUID]/sources/[sourceID]/integrations/[integrationID]/edit-message/[messageID]": [12,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';